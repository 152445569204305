import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import {mediaLinks} from "./artists.module.css"

import Grid from '@mui/material/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faSoundcloud, faTwitter, faInstagram, faBandcamp, faItunes, faTiktok } from '@fortawesome/free-brands-svg-icons'


const ArtistsPage = () => (
  <Layout disableFlexContainer={true}>
    <Seo title="Alex Rapp" />
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12} md={5}>
        <h2>
            Alex Rapp
        </h2>
      </Grid>
      <Grid item xs={12} md={5}>
        {/* Nothing */}
      </Grid>
      <Grid item xs={12} md={5}>
        <div style={{position: 'relative', overflow: 'hidden', height: '0', paddingBottom: '56.25%'}}>
          <iframe 
          style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', borderRadius: '12px'}}
          src="https://open.spotify.com/embed/album/2lK0Ii3UGt59ymaTjxV0KO?utm_source=generator&theme=0" 
          width="100%" height="380" frameBorder="0" allowfullscreen="" 
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy">
          </iframe>
        </div>
        <div style={{marginTop: '2rem', borderBottom: 'solid 1px black'}}></div>
        <div className={mediaLinks}>
          <a href="https://facebook.com/alexrappmusic" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
          </a>
          <a href="https://www.instagram.com/thats_arapp/" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
          </a>
          {/* <a href="https://www.tiktok.com/@alexrapp" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faTiktok}></FontAwesomeIcon>
          </a> */}
          
          <a href="https://soundcloud.com/arapp-2" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faSoundcloud}></FontAwesomeIcon>
          </a>
          {/* <a href="https://twitter.com/writtenbydaria" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
          </a> */}
          <a href="https://alexrapp.bandcamp.com/releases" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faBandcamp}></FontAwesomeIcon>
          </a>
          <a href="https://music.apple.com/es/artist/alex-rapp/1523396688?l=en" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faItunes}></FontAwesomeIcon>
          </a>
        </div>
      </Grid>


      <Grid item xs={12} md={5}>
        <div style={{position: 'relative', overflow: 'hidden', height: '0', paddingBottom: '56.25%'}}>
          <iframe 
          title="youtube-video"
          style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
          width="560" height="315" src="https://www.youtube.com/embed/q6GTz2kX0AM" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div style={{marginTop: '1rem'}}>
          <h4>
            BIO
          </h4>
          <p>
          Alex Rapp’s hypnotic pop unites experimentation with confession, bringing a unique blend of soulful electronica to the streets of Berlin. The DC native is equal parts vocalist, producer, songwriter and music technologist. With dreamy harmonies and maximalist soundscapes, she unpacks passionate stories of love and heartbreak, drawing you into her sonic world. Her latest EP, “Spiralling”, examines her own cycles of dependence, and sees Alex trying (and sometimes failing) to find power in independence.  Constantly curious, Alex often incorporates new technologies into her art. Most recently, she has been working with the Mimu glove for live performances. The glove enables her to move freely while manipulating her electronic productions, escaping the physical boundaries created by an extensive technical setup. As an Ableton teacher, she has spent much of the last few years teaching music production to youth groups, particularly those focused on empowering young female creatives, such as Beats by Girlz and She Knows Tech. Drawing on her own experiences in the industry, she hopes to empower young women to find their voice and speak their truth.
          </p>
        </div>
      </Grid>
       
    </Grid>
  </Layout>
)

export default ArtistsPage
 